<template>
  <!-- size selector -->
  <b-form-group id="groupResolutionSize" >
    <BaseSelect
      :ref="id"
      :name="id"
      v-model="valueComputed"
      :options="options"
      :reduce="opt => opt.sku"
      :placeholder="$t('selectResolutionSize')"
      :validations="validations"
    />
    <div class="form-errors is-invalid" v-if="errorMessage">
      <p v-html="errorMessage"></p>
    </div>
  </b-form-group>
</template>

<script>
import BaseSelect from '@/theme/components/Form/BaseSelect'
import Rma from '@/services/Rma'
import Logger from '@/services/Logger'

export default {
  data: () => ({
    options: [],
    errorMessage: ''
  }),
  props: {
    value: {
      type: String
    },
    sku: {
      type: String,
      required: true
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    id () {
      return 'resolution_size_' + this.sku
    },
    valueComputed: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    init () {
      const _this = this
      this.loading(true)
      this.errorMessage = ''
      Rma.searchProductAvailable({
        simpleSku: this.sku,
        storeViewCode: this.$store.state.ui.storeViewCode,
        $store: this.$store
      }).then(options => {
        _this.options = options
      })
        .catch(err => {
          Logger.error(err)
          _this.errorMessage = _this.$terr.message()
        })
        .finally(() => {
          _this.loading(false)
        })
    },
    loading (status) {
      this.$refs[this.id] && this.$refs[this.id].loading(status)
    }
  },
  mounted () {
    this.init()
  },
  components: {
    BaseSelect
  }
}
</script>
