var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rma-new-products"},[_c('b-table',{attrs:{"stacked":"md","responsive":"","res":"","items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [_c('b-form-checkbox',{attrs:{"disabled":row.item.qty_available === 0},on:{"change":row.toggleDetails},model:{value:(row.detailsShowing),callback:function ($$v) {_vm.$set(row, "detailsShowing", $$v)},expression:"row.detailsShowing"}})]}},{key:"cell(item_image)",fn:function(ref){
var item = ref.item;
return [_c('article',{staticClass:"rma-product"},[_c('img-product',{staticClass:"rma-product_img pr-2",attrs:{"imgPath":item.image_url,"alt":item.item_name,"styleImage":{ maxWidth: '60px' }}}),_c('span',{staticClass:"rma-product_name"},[_vm._v(_vm._s(item.item_name))])],1)]}},{key:"row-details",fn:function(row){return [_c('b-row',[_c('b-col',{staticClass:"quantity",attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupQty"}},[_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'qty_' + row.item.item_sku,"options":_vm.optionAvailable(row.item.qty_available),"placeholder":_vm.$t('selectQty'),"validations":[
                {
                  condition: _vm.errors.has('qty_' + row.item.item_sku),
                  text: _vm.errors.first('qty_' + row.item.item_sku)
                }
              ]},model:{value:(row.item.dictionarySelected.qty_requested),callback:function ($$v) {_vm.$set(row.item.dictionarySelected, "qty_requested", $$v)},expression:"row.item.dictionarySelected.qty_requested"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupReason"}},[_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'reason_' + row.item.item_sku,"validations":[
                {
                  condition: _vm.errors.has('reason_' + row.item.item_sku),
                  text: _vm.errors.first('reason_' + row.item.item_sku)
                }
              ],"options":_vm.dictionary.reasons,"reduce":function (opt) { return opt.value; },"placeholder":_vm.$t('selectReason')},on:{"input":function($event){return _vm.reasonChange(row.item.dictionarySelected.reason_id, row.item)}},model:{value:(row.item.dictionarySelected.reason_id),callback:function ($$v) {_vm.$set(row.item.dictionarySelected, "reason_id", $$v)},expression:"row.item.dictionarySelected.reason_id"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupCondition"}},[_c('BaseSelect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'condition_' + row.item.item_sku,"validations":[
                {
                  condition: _vm.errors.has('condition_' + row.item.item_sku),
                  text: _vm.errors.first('condition_' + row.item.item_sku)
                }
              ],"reduce":function (opt) { return opt.value; },"options":_vm.conditions,"placeholder":_vm.$t('selectCondition')},model:{value:(row.item.dictionarySelected.condition_id),callback:function ($$v) {_vm.$set(row.item.dictionarySelected, "condition_id", $$v)},expression:"row.item.dictionarySelected.condition_id"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"id":"groupResolution"}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],ref:'resolution_' + row.item.item_sku,attrs:{"name":'resolution_' + row.item.item_sku,"label":"label","validations":[
                {
                  condition: _vm.errors.has('resolution_' + row.item.item_sku),
                  text: _vm.errors.first('resolution_' + row.item.item_sku)
                }
              ],"options":_vm.resolutionsRemapped,"reduce":function (opt) { return opt.value; },"placeholder":_vm.$t('selectResolution')},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
              var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('span',{staticClass:"arrow-down-end"})])]}},{key:"option",fn:function(ref){
              var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}}],null,true),model:{value:(row.item.dictionarySelected.resolution_id),callback:function ($$v) {_vm.$set(row.item.dictionarySelected, "resolution_id", $$v)},expression:"row.item.dictionarySelected.resolution_id"}})],1),(row.item.dictionarySelected.resolution_id === '1')?_c('RmaProductsSizeSelector',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":'resolution_size_' + row.item.item_sku,"validations":[
              {
                condition: _vm.errors.has('resolution_size_' + row.item.item_sku),
                text: _vm.errors.first('resolution_size_' + row.item.item_sku)
              }
            ],"sku":row.item.item_sku},model:{value:(row.item.dictionarySelected.resolution_sku),callback:function ($$v) {_vm.$set(row.item.dictionarySelected, "resolution_sku", $$v)},expression:"row.item.dictionarySelected.resolution_sku"}}):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }