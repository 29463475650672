<template>
  <div class="rma-new-products">
    <b-table stacked="md" responsive res :items="items" :fields="fields" >
      <template v-slot:cell(actions)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          @change="row.toggleDetails"
          :disabled="row.item.qty_available === 0"
          />
      </template>

      <template #cell(item_image)="{ item }">
        <article class="rma-product">
          <img-product
            class="rma-product_img pr-2"
            :imgPath="item.image_url"
            :alt="item.item_name"
            :styleImage="{ maxWidth: '60px' }"
          />
          <span class="rma-product_name">{{item.item_name}}</span>
        </article>
      </template>

      <template v-slot:row-details="row">
        <b-row>
          <b-col sm="6" class="quantity">
            <b-form-group id="groupQty" >
              <BaseSelect
                :name="'qty_' + row.item.item_sku"
                v-model="row.item.dictionarySelected.qty_requested"
                :options="optionAvailable(row.item.qty_available)"
                :placeholder="$t('selectQty')"
                v-validate="'required'"
                :validations="[
                  {
                    condition: errors.has('qty_' + row.item.item_sku),
                    text: errors.first('qty_' + row.item.item_sku)
                  }
                ]"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group id="groupReason" >
               <!-- FF LOGICA CAMBIO TAGLIA
                 <BaseSelect
                :name="'reason_' + row.item.item_sku"
                v-validate="'required'"
                :validations="[
                  {
                    condition: errors.has('reason_' + row.item.item_sku),
                    text: errors.first('reason_' + row.item.item_sku)
                  }
                ]"
                v-model="row.item.dictionarySelected.reason_id"
                :options="reasonRemapped"
                :reduce="opt => opt.value"
                :placeholder="$t('selectReason')"
                @input="reasonChange(row.item.dictionarySelected.reason_id, row.item)"
              /> -->

              <BaseSelect
                :name="'reason_' + row.item.item_sku"
                v-validate="'required'"
                :validations="[
                  {
                    condition: errors.has('reason_' + row.item.item_sku),
                    text: errors.first('reason_' + row.item.item_sku)
                  }
                ]"
                v-model="row.item.dictionarySelected.reason_id"
                :options="dictionary.reasons"
                :reduce="opt => opt.value"
                :placeholder="$t('selectReason')"
                @input="reasonChange(row.item.dictionarySelected.reason_id, row.item)"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group id="groupCondition">
              <BaseSelect
                :name="'condition_' + row.item.item_sku"
                v-validate="'required'"
                :validations="[
                  {
                    condition: errors.has('condition_' + row.item.item_sku),
                    text: errors.first('condition_' + row.item.item_sku)
                  }
                ]"
                v-model="row.item.dictionarySelected.condition_id"
                :reduce="opt => opt.value"
                :options="conditions"
                :placeholder="$t('selectCondition')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group id="groupResolution">
              <v-select
                :ref="'resolution_' + row.item.item_sku"
                :name="'resolution_' + row.item.item_sku"
                v-validate="'required'"
                label="label"
                :validations="[
                  {
                    condition: errors.has('resolution_' + row.item.item_sku),
                    text: errors.first('resolution_' + row.item.item_sku)
                  }
                ]"
                v-model="row.item.dictionarySelected.resolution_id"
                :options="resolutionsRemapped"
                :reduce="opt => opt.value"
                :placeholder="$t('selectResolution')"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <span class="arrow-down-end"></span>
                  </span>
                </template>
                <template #option="{ label }">
                  <span>{{label}}</span>
                </template>
              </v-select>
            </b-form-group>

            <!-- size selector -->
            <RmaProductsSizeSelector
              v-if="row.item.dictionarySelected.resolution_id === '1'"
              :name="'resolution_size_' + row.item.item_sku"
              v-validate="'required'"
              :validations="[
                {
                  condition: errors.has('resolution_size_' + row.item.item_sku),
                  text: errors.first('resolution_size_' + row.item.item_sku)
                }
              ]"
              v-model="row.item.dictionarySelected.resolution_sku"
              :sku="row.item.item_sku"
            />
          </b-col>
        </b-row>
      </template>
    </b-table>
  </div>
</template>

<script>
/* import moment from 'moment' */
import Config from '@/config'
import ImgProduct from '@/components/Product/Image'
import BaseSelect from '@/theme/components/Form/BaseSelect'
import RmaProductsSizeSelector from './RmaProductsSizeSelector'

// FABIANA FILIPPI LOGICA CAMBIO TAGLIA
// const enableExchangeMap = [
//   '7', // non va bene per me
//   '8', // non si adatta a me
//   '9', // veste troppo grande
//   '10', // veste troppo piccolo
//   '11' // taglia differente
// ]

export default {
  inject: ['$validator'],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    dictionary: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    fields () {
      return [{
        key: 'actions',
        label: ''
      },
      {
        key: 'item_image',
        label: this.$t('product')
      },
      {
        key: 'color',
        label: this.$t('color')
      }
      ]
    },
    resolutionsRemapped () {
      let resolutions = []
      if (this.dictionary && this.dictionary.resolutions) {
        this.dictionary.resolutions.forEach((item) => {
          let removeFrom = Config.Theme.rmaResolutionToDisable
          if (!removeFrom.includes(parseInt(item.value))) {
            resolutions.push(item)
          }
        })
      }
      return resolutions
    },
    conditions () {
      return this.dictionary.conditions.map((condition) => {
        return {
          value: condition.value,
          label: this.$t(`conditions.${condition.label}`)
        }
      })
    }
  },
  methods: {
    optionAvailable (maxQty) {
      return Array.from({ length: maxQty }, (v, k) => k + 1)
    },
    isValid () {
      return this.$validator.validateAll()
    },
    reasonChange (itemValue, item) {
      /*
         !!ATZN!!
         FIX 27/10
         ON PRODUCTION EXCHANGE NOT EXIST!
         IF YOU NEED TO ACTIVATE THE LOGIC HAVE TO IMPORT FABIANA FILIPPI LOGIC WITH MAPS
         !!ATZN!!
      */

      this.$refs[`resolution_${item.item_sku}`].options[0]['enabled'] = true

      // Commented out to understand better the request
      // date.09-07-21
      // if (itemValue && itemValue === '5') {
      //   // is wrong size, enable exchange
      //   this.$refs[`resolution_${item.item_sku}`].options[0]['enabled'] = true
      // } else {
      //   // disable exchange
      //   this.$refs[`resolution_${item.item_sku}`].options[0]['enabled'] = false
      //   // empty
      //   item.dictionarySelected.resolution_id = null
      // }
    }
  },
  // FABIANA FILIPPI LOGICA CAMBIO TAGLIA
  //  reasonChange (itemValue, item) {
  //     // Commented out to understand better the request
  //     // date.09-07-21
  //     let options = this.$refs[`resolution_${item.item_sku}`].options
  //     let exchange = find(options, { value: '1' })
  //     if (exchange) {
  //       if (itemValue && enableExchangeMap.includes(itemValue)) {
  //         // is wrong size, enable exchange
  //         exchange['enabled'] = true
  //       } else {
  //         // disable exchange
  //         exchange['enabled'] = false
  //         // empty
  //         item.dictionarySelected.resolution_id = null
  //       }
  //     }
  //   }

  components: {
    ImgProduct,
    BaseSelect,
    RmaProductsSizeSelector
  }
}
</script>
